@import 'src/styles/config';
.root {
  display: grid;
  grid-template-columns: $panel-width 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'panel header'
    'panel content';
  flex-grow: 1;
  & > * {
    min-width: 0;
  }
}
.header {
  grid-area: header;
}
.panel {
  grid-area: panel;
}
.content {
  padding: 2rem 2.4rem;
  grid-area: content;
}
