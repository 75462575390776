@import './config/colors';

:root {
  --data-grid-cell-color: #{$color-grey};
  --data-grid-cell-color-border: #{$color-grey-lines};
  --data-grid-cell-head-bg-color: #{$color-grey-light};
  --data-grid-editor-color-bg: #{$color-secondary};
  --data-grid-editor-color-bg-hover: #{$color-grey-light};
  --data-grid-empty-color: #{$color-grey-light};
}
