@import 'src/styles/config';
.root {
}
.paper {
  width: $panel-width;
  transition: width 0.25s ease-in-out, box-shadow 0.25s ease-in-out, background 0.25s ease-in-out;
  &Open {
    width: $panel-width-open;
  }
}
.inner {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  height: 100%;
  overflow: hidden;
  & > * {
    min-width: 0;
  }

  &:before {
    content: '';
    display: block;
    width: $panel-width;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $color-primary;
  }
}
