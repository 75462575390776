@import 'src/styles/config';

.wrap {
  min-height: 100%;
  display: grid;
  grid-template-areas: 'header content' 'footer footer';
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
  flex-grow: 1;
  width: 100%;
}

.header {
  grid-area: header;
  background: $color-primary url('../../images/bg-login.png') no-repeat;
  background-size: cover;
}

.content {
  position: relative;
  grid-area: content;
  padding: 0 3rem;
  display: grid;
}

.footer {
  color: white;
  grid-area: footer;
  position: absolute;
  bottom: 1.8rem;
  left: 0;
  padding: 0 3rem;
  width: 50%;
}

.form {
  max-width: 37rem;
  width: 100%;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.logo {
  width: 13.8rem;
  margin-bottom: 4rem;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .wrap {
    grid-template-areas: 'header' 'content' 'footer';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    padding: 0;
    overflow: hidden;
  }
  .header {
    padding: 1.6rem;
    min-height: 8rem;
  }
  .logo {
    width: 20rem;
  }
  .content {
    padding: 0 1.6rem 5rem;
  }
  .footer {
    padding: 1rem 1.6rem;
    color: #000;
    text-align: center;
    position: static;
    width: auto;
  }
}
