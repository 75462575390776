$color-primary: #326073;

$color-secondary: #2a9d8f;
$color-secondary-dark: #269184;

$color-grey-dark: #393939;
$color-grey: #9c9c9c;
$color-grey-lines: #ededed;
$color-grey-light: #f8f8f8;
$color-black: #262b31;

$color-red: #e12037;
$color-green: #5faf59;
