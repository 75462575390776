@import 'src/styles/config';
.root {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: capitalize;
  border: 1px solid $color-grey-lines;
  min-height: 3.6rem;
  padding: 0.6rem 1.4rem;
  letter-spacing: normal;
  color: $color-secondary-dark;
}
